import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

import { LscEvents, StepAnalyticsEventEnum } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { ClassroomService } from '../../../../../services/classroom.service';

export interface BulkActionClassesData {
  title: string;
  subtitle: string;
  searchTitle: string;
  selectResultsTitle: string;
  finalStepTitle: string;
  finalStepConfirmation: string;
  action: 'activate' | 'archive' | 'delete' | 'clear';
  // Should the last value for function be 'remove_student'? I don't see clearRosters passed in anywhere
  function: 'activate' | 'archive' | 'archiveSuspended' | 'delete' | 'clearRosters';
  hideFilterOptions?: boolean;
}

@Component({
  selector: 'app-classes-bulk-action-dialog',
  templateUrl: './classes-bulk-action-dialog.component.html',
  styleUrls: ['./classes-bulk-action-dialog.component.scss'],
})
export class ClassesBulkActionDialogComponent implements OnInit {
  loading = true;
  isActiveJob = false;
  title: string;
  subTitle: string;
  searchTitle: string;
  action: string;
  function: string;
  selectResultsTitle: string;
  finalStepTitle: string;
  finalStepConfirmation: string;
  hideFilterOptions = false;
  private stepperSelectedIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BulkActionClassesData,
    private classroomService: ClassroomService,
    public dialogRef: MatDialogRef<ClassesBulkActionDialogComponent>,
    private _firebaseAnalytics: FirebaseAnalyticsService,

  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.subTitle = this.data.subtitle;
    this.searchTitle = this.data.searchTitle;
    this.action = this.data.action;
    this.function = this.data.function;
    this.selectResultsTitle = this.data.selectResultsTitle;
    this.finalStepTitle = this.data.finalStepTitle;
    this.finalStepConfirmation = this.data.finalStepConfirmation;
    this.hideFilterOptions = this.data.hideFilterOptions;

    this.classroomService.checkIfBulkJobIsRunning().subscribe(
      isActiveJob => {
        this.loading = false;
        this.isActiveJob = isActiveJob;
      }
    );
  }

  setStepperSelectedIndex(stepperSelectedIndex: number): void {
    this.stepperSelectedIndex = stepperSelectedIndex;
  }

  closeDialog(result?: boolean): void {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[this.action][this.stepperSelectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close(result);
  }

}
