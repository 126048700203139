import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';

import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import {AgGridModule} from '@ag-grid-community/angular';
import {NgSelectModule} from '@ng-select/ng-select';

import { AgGridTextWithDefaultIconCellRendererComponent } from '@cdw-ae/angular-ui-cdw-ag-grid';
import { LittleSisForClassroomAgGridModule } from '@cdw-ae/little-sis-for-classroom-ag-grid';

import {NavTogglerModule} from '../../layout/components/nav-toggler/nav-toggler.module';
import {ClassroomService} from '../../services/classroom.service';
import {LocalStorageService} from '../../services/storage.service';
import {LoggedInGuard} from '../auth/services/admin-auth-guard/loggedIn.guard';
import {CoModule} from '../common/co.module';
import {CustomDialogsModule} from '../custom-dialogs/custom-dialogs.module';
import {InsightsService} from '../insights/services/insights.service';
import {JobModule} from '../jobs/job.module';
import {AccessFiltersToolPanel} from './components/ag-grid/tool-panels/access-filters-tool-panel/access-filters-tool-panel.component';
import {SavedViewsToolPanelComponent} from './components/ag-grid/tool-panels/saved-views-tool-panel/saved-views-tool-panel.component';
import {ClassActivateDialogComponent} from './components/dialogs/activate-classes/activate-class-dialog.component';
import {ClassArchiveDialogComponent} from './components/dialogs/archive-classes/archive-class-dialog.component';
import {BulkAddCoTeacherDialogComponent} from './components/dialogs/bulk-add-coTeacher/bulk-addCoTeacher.component';
import {BulkAddStudentDialogComponent} from './components/dialogs/bulk-add-students/bulk-add-students.component';
import {BulkDeleteClassesDialogComponent} from './components/dialogs/bulk-deleteClasses/bulk-deleteClasses-dialog.component';
import {BulkRemoveCoteacherDialogComponent} from './components/dialogs/bulk-removeCoteacher/bulk-removeCoteacher-dialog.component';
import {BulkRemoveStudentDialogComponent} from './components/dialogs/bulk-removeStudents/bulk-removeStudent-dialog.component';
import {ChangePrimaryTeacherComponent} from './components/dialogs/change-primary-teacher/change-primary-teacher.component';
import {ClassesBulkActionDialogComponent} from './components/dialogs/classes-bulk-action/classes-bulk-action-dialog.component';
import {ClassesBulkActionComponent} from './components/dialogs/classes-bulk-action/classes-bulk-action.component';
import {ErrorDialogComponent} from './components/dialogs/error-confirm/error-dialog.component';
import {GridDialogComponent} from './components/dialogs/grid-dialog.component';
import {JoinClassAsStudentComponent} from './components/dialogs/join-class-as-student/join-class-as-student.component';
import {RefreshSelectedDialogComponent} from './components/dialogs/refresh-selected/refresh-selected.component';
import {SaveAccessFiltersDialogComponent} from './components/dialogs/save-filters-settings/save-filters-settings';
import {SaveGridSettingsDialogComponent} from './components/dialogs/save-grid-settings/save-grid-settings';
import {UpdateTagsDialogComponent} from './components/dialogs/update-tags/update-tags.component';
import {GrdComponent} from './components/grd/grd.component';
import {CustomListboxComponent} from './components/user-custom-listbox/user-custom-listbox.component';
import {ExportClassroomService} from './services/export-classroom.service';
import {GridStateService} from './services/grid-state.service';
import {GridService} from './services/grid.service';
import {StudentExplorerService} from './services/student-explorer.service';

const routes = [
  {
    path: 'explorer',
    component: GrdComponent,
    canActivate: [LoggedInGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes), // { enableTracing: true }),

    FlexLayoutModule,
    NgSelectModule,
    CoModule,
    CustomDialogsModule,
    NgxMaterialTimepickerModule,
    JobModule,
    NavTogglerModule,
    NgxDaterangepickerMd,

    AgGridModule,

    LittleSisForClassroomAgGridModule

  ],
  declarations: [
    GrdComponent,
    RefreshSelectedDialogComponent,
    UpdateTagsDialogComponent,
    GridDialogComponent,
    ClassActivateDialogComponent,
    ClassArchiveDialogComponent,
    ErrorDialogComponent,
    BulkDeleteClassesDialogComponent,
    BulkAddCoTeacherDialogComponent,
    BulkAddStudentDialogComponent,
    ClassesBulkActionDialogComponent,
    ClassesBulkActionComponent,
    SaveGridSettingsDialogComponent,
    BulkRemoveCoteacherDialogComponent,
    BulkRemoveStudentDialogComponent,
    CustomListboxComponent,
    ChangePrimaryTeacherComponent,
    SaveAccessFiltersDialogComponent,
    JoinClassAsStudentComponent,
    AgGridTextWithDefaultIconCellRendererComponent,
    AccessFiltersToolPanel,
    SavedViewsToolPanelComponent,
  ],
  providers: [
    GridService,
    GridStateService,
    ClassroomService,
    LocalStorageService,
    InsightsService,
    StudentExplorerService,
    ExportClassroomService
  ],
  exports: [
    JobModule
  ]
})
export class ExplorerModule { }
