import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {
  Injectable,
  OnDestroy,
} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';

import * as moment from 'moment';
import {
  Observable,
  Subject,
} from 'rxjs';
import {
  first,
  takeUntil,
} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {AuthService} from '../../auth/services/Auth/auth.service';

export interface ListResponse {
  /**
   * Paged list of items matching the LIST request query
   */
  data: any[];
  /**
   * Total number of items matching the LIST request query
   */
  count: number;

  /**
   * Total number of items (optional)
   */
  total?: number;
}

@Injectable({providedIn: 'root'})
export class TaskService implements OnDestroy {

  sync: {
    classrooms: any;
    users: any;
    jobs: any[];
  };

  tasks: any;
  task$: any; // Not an observable despite name

  private httpOptions: { headers: HttpHeaders };

  private serviceUrls = environment.urls.userServices;
  private status = new Subject();
  private metadataStatus = new Subject();

  private onDestroy$: Subject<void> = new Subject<void>();

  private user = new Subject();
  private userTaskHistory = new Subject(); // Type is BulkJob[]?

  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient
  ) {

    this.authService.loggedInTrigger.subscribe(() => {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      this.getSync();
      this.getMetadataSync();

      this.sync = {
        classrooms: {},
        users: {},
        jobs: []
      };

      this.status.next(this.sync);

      this._userWatch();

      this.task$ = {
        active: {
          progress: 0
        }
      };


      this.user.next(this.task$);
    });

    this.authService.loggedOutTrigger.subscribe(() => {
      this.ngOnDestroy();
    });
  }

  getStatus(): Observable<any> {
    return this.status.asObservable();
  }

  getSync() {

    this.authService.getCustomerId().pipe(takeUntil(this.onDestroy$)).subscribe((data) => {

      if (!data) {
        this.status.next(this.sync);
        return;
      }

      const customerId = data;

      this.firestore.collection(`customers/${customerId}/sync`).snapshotChanges().pipe(
        takeUntil(this.onDestroy$)).subscribe(data => {

        data.map(e => {
          this.sync[e.payload.doc.id] = e.payload.doc.data();
        });

        this._processRunTimes();

        this.status.next(this.sync);

      });

    });
  }

  getMetadataStatus(): Observable<any> {
    return this.metadataStatus.asObservable();
  }

  getMetadataSync() {

    return this.authService.getCustomerId().pipe(takeUntil(this.onDestroy$)).subscribe((data) => {

      if (!data) {
        return;
      }

      const customerId = data;

      this.firestore.doc(`customers/${customerId}/sync/metadata/meta/state`).snapshotChanges()
      .pipe(takeUntil(this.onDestroy$)).subscribe(data => {

        let d;
        if (data.payload.exists) {
          d = data.payload.data();
          this.metadataStatus.next(d);
        } else {
          this.metadataStatus.next({});
        }

      });

      // this.firestore.doc(`customers/${customerId}/sync/metadata`).snapshotChanges().subscribe(data => {
      //   if (data.payload.exists) {
      //     let d = data.payload.data();
      //     this.metadataStatus.next(d);
      //   } else {
      //     this.metadataStatus.next({});
      //   }
      // });

    });

  }

  cancel(type) {

    // TODO: Purge the job from Cloud Tasks

    const update = {
      status: 'cancelling',
      cancelled: true,
      cancel: true
    };

    this.authService.getCustomerId().pipe(takeUntil(this.onDestroy$)).subscribe((customerId) => {

      const ref = this.firestore.doc(`customers/${customerId}/sync/${type}`);

      ref.set(update, {
        merge: true
      });

    });
  }

  cancelBulkJob(type: string) {

    this.authService.getActiveUser()
      .pipe(first())
      .subscribe((user) => {
        if (!user || !user.auth.fbToken || !this.afAuth.currentUser) {
          return;
        }

        const customerId = user.gSuiteId;
        const userId = user.profile.id;

        const ref = this.firestore.doc(`customers/${customerId}/users/${userId}/tasks/${type}`);

        const update = {
          status: 'cancelling'
        };

        ref.set(update, {
          merge: true
        });
      });
  }


  restartBulkJob(type: string) {
    this.authService.getActiveUser()
    .pipe(first())
    .subscribe((user) => {
      if (!user || !user.auth.fbToken || !this.afAuth.currentUser) {
        return;
      }

      const customerId = user.gSuiteId;
      const userId = user.profile.id;

      const ref = this.firestore.doc(`customers/${customerId}/users/${userId}/tasks/${type}`);

      const update = {
        status: 'active'
      };

      ref.set(update, {
        merge: true
      });
    });
  }

  _processRunTimes() {

    const now = moment();

    if (this.sync.users && this.sync.users.status === 'active' && this.sync.users.start) {
      this.sync.users.runTime = now.diff(moment.unix(this.sync.users.start.seconds).utc(), 's');
    }

    if (this.sync.classrooms && this.sync.classrooms.status === 'active' && this.sync.classrooms.start) {
      this.sync.classrooms.runTime = now.diff(moment.unix(this.sync.classrooms.start.seconds).utc(), 's');
    }

    // TODO process other background tasks


  }

  getUser(): Observable<any> {
    return this.user.asObservable();
  }

  getUserTaskHistory(): Observable<any> {
    return this.userTaskHistory.asObservable();
  }

  bindToUser(user) {
    if (!user || !user.auth.fbToken || !this.afAuth.currentUser) {
      return;
    }

    const customerId = user.gSuiteId;
    const userId = user.profile.id;

    // this.afAuth.auth.currentUser.getIdTokenResult()
    //   .then((idTokenResult) => {
    //   })
    //   .catch((error) => {
    //   });

    this.tasks = this.firestore.collection(`customers/${customerId}/users/${userId}/tasks`);

    this.tasks.snapshotChanges().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      let changes = false;
      data.map(e => {
        if (JSON.stringify(this.task$[e.payload.doc.id]) !== JSON.stringify(e.payload.doc.data())) {
          changes = true;
          this.task$[e.payload.doc.id] = e.payload.doc.data();
        }
      });
      if (changes) {
        this._processUserRunTime();
        this.user.next(this.task$);
      }
    });


    const tasksHistoryCollection = this.firestore.collection(`customers/${customerId}/users/${userId}/tasksHistory`);

    // const tasksHistory = {};

    tasksHistoryCollection.snapshotChanges().pipe(takeUntil(this.onDestroy$)).subscribe(data => {

      // data.map(e => {
      //   tasksHistory[e.payload.doc.id] = e.payload.doc.data();
      // });
      // this._processUserRunTime();
      const tasksHistory = data.map(e => e.payload.doc.data());

      this.userTaskHistory.next(tasksHistory);
    });
  }


  _processUserRunTime() {

    const now = moment();

    if (this.task$.active && this.task$.active.status === 'active' && this.task$.active.start) {
      this.task$.active.runTime = now.diff(moment.unix(this.task$.active.start).utc(), 's');
    }

  }


  sendTask(request): Observable<any> {

    if (!request.refresh) {
      request.refresh = {roster: true};
    }

    const postBody = request;

    const url = this.serviceUrls.classrooms.tasks;
    return this.http.post<ListResponse>(url, postBody, this.httpOptions);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }


  private _userWatch() {
    this.authService.getActiveUser().pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      if (user && user.auth && user.auth.fbToken) {
        this.bindToUser(user);
      }
    });
  }

}
