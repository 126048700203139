
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { from, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthService } from '../Auth/auth.service';

@Injectable()
export class ApiRequestInterceptorService implements HttpInterceptor {

  constructor(public auth: AuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.auth.getCurrentUser()) {

      const error = new HttpErrorResponse({
        error: 'No current user',
        headers: request.headers,
        status: 403,
        statusText: 'No current user',
        url: request.url || undefined
      });

      return this.handleAuthError(error);
    }

    return from(this.auth.getCurrentUserIdToken()).pipe(switchMap(fbIdToken => {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getCurrentUser().auth.jwt}`,
          'x-cdw-app-name': 'lsc',
          'x-cdw-fbauth-token': `Bearer ${fbIdToken}`,
        },
      });
      return next.handle(request);
    })).pipe(catchError(error => this.handleAuthError(error)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // console.log('handleAuthError', err);
    if (err.status === 401 || err.status === 403) {
      this.auth.signOut(false);
      return of(err.message);
    }
    return throwError(err);
  }
}




