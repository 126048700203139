import {
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import * as moment from 'moment';

import { LscEvents, StepAnalyticsEvent, StepAnalyticsEventEnum } from '../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { FirebaseAnalyticsService } from '../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {
  ClassActions,
  Course,
} from '../../../../services/classroom.service';
import { FirestoreCustomerService } from '../../../../services/firestore-customer.service';
import { AuthService } from '../../../auth/services/Auth/auth.service';
import { ApiLookupService } from '../../../auth/services/api-lookup/api-lookup.service';
import { GenericBulkActionComponent } from '../../../explorer/components/dialogs/generic-bulk-action.component';

export interface Time {
  value: string;
  label: string;
}

export const ADD_SELF_TO_CLASSES_STEPPER_STEPS = 4;
export const ADD_SELF_TO_CLASSES = 'explorerAddSelfToClasses';

@Component({
  selector: 'app-add-self-to-classes',
  templateUrl: './add-self-to-classes.html',
  styleUrls: ['./add-self-to-classes.scss']
})

export class AddSelfToClassesComponent extends GenericBulkActionComponent implements OnInit {

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  columnDefsSummary;
  gridOptionsSummary;

  userActionTemplate: string | object;
  customerActionTemplate: string | object;
  joinAsCoteacher: string;

  summaryOfChanges = [];

  grids: {
    classes: {
      height: number;
    };
    summary: {
      height: number;
    };
  };

  endDate;
  endTime;

  selectedAccessTime = '999';
  minDate = new Date();

  accessTimes: Time[] = [
    {value: '1', label: 'For 1 hour'},
    {value: '24', label: 'For 1 Day'},
    {value: 'expiry', label: 'Until'},
    {value: '999', label: 'Do not remove'}
  ];

  selectedItems: string[] = [];
  selectedClasses: Course[] = [];


  constructor(
    public apiLookup: ApiLookupService,
    public dialogRef: MatDialogRef<AddSelfToClassesComponent>,
    private _firebaseAnalytics: FirebaseAnalyticsService,
    protected _authSvc: AuthService,
    protected _firestoreCustomerService: FirestoreCustomerService,
    @Inject(MAT_DIALOG_DATA) public data: ClassActions) {

    super(apiLookup, data, _authSvc, _firestoreCustomerService);

    const additionalCols = [{
      headerName: 'Already in class?',
      field: 'alreadyInClass',
      width: 80,
      filter: false,
      cellClass: 'text-center',
      hide: false,
      cellRenderer: 'booleanCellRenderer'
    }];


    this.columnDefs = [...this.columnDefs, ...additionalCols];

    this.gridOptions = {
      ...this.gridOptions,
      isRowSelectable: (rowNode) => !rowNode.data.alreadyInClass,
      rowClassRules: {
        'declined-50': 'data && data.alreadyInClass',
      },
    };
    this.columnDefsSummary = [

      {
        headerName: 'Action',
        field: 'action',
        width: 60,
        filter: false,
        cellClass: 'text-center',
        hide: false,
        suppressMenu: true
      },
      {
        headerName: 'Course',
        field: 'name',
        width: 120,
        filter: 'agTextColumnFilter',
        cellRenderer: 'classroomCellRenderer',
        autoHeight: true,
        suppressMenu: true
      }
    ];


    this.gridOptionsSummary = {
      columnDefs: this.columnDefsSummary,
      components: this.frameworkComponents,
      context: this.context,

      rowModelType: 'clientSide',
      rowSelection: false,
      cacheBlockSize: 100,
      maxBlocksInCache: 2,
      animateRows: true,
      clipboardDelimiter: ',',
      pagination: false,
      defaultColDef: {
        filter: false,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: false,
        hide: false,
        resizable: true,
        sortable: true,
      },
      rowClassRules: {
        'declined-50': 'data && data.action === \'Already a co-teacher\'',
      },
    };
    this.grids = {
      classes: {
        height: Math.min(80 + this.data.selectedClasses.length * 60, 300)
      },
      summary: {
        height: Math.min(40 + this.data.selectedClasses.length  * 60, 200)
      }
    };

  }


  ngOnInit(): void {
    const analyticsEvent = {
      action: 'selfAddCoTeacher',
      properties: {
        category: 'admin',
        label: 'start'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);

    this.gridOptions.rowData = this.data.selectedClasses;
    this.sendNotification = true;

    const currentUserEmail = this._authSvc.getCurrentUser().profile.primaryEmail;

    this.data.selectedClasses.map( cls => {
      // @ts-ignore
      cls.alreadyInClass = Boolean(cls.Owner.primaryEmail === currentUserEmail || cls.coteachers.find(teacher => teacher.primaryEmail === currentUserEmail));
    });

    this.validClasses = this.data.selectedClasses.reduce((accum, curr) => {

      const alreadyInClass = Boolean(curr.coteachers.find(teacher => teacher.primaryEmail === currentUserEmail));

      if (!alreadyInClass) {
        accum.push(curr);
        this.summaryOfChanges.push({action:'Join as co-teacher', class:curr});
      } else {
        this.invalidClasses.push(curr);
        this.summaryOfChanges.push({action:'Already a co-teacher', class: curr});
      }
      return accum;
    }, []);

    this.validClasses.sort((a, b) => {
      if (a.primaryTeacherEmail > b.primaryTeacherEmail) {
        return 1;
      } else if (a.primaryTeacherEmail < b.primaryTeacherEmail) {
        return -1;
      }
      return 0;
    });

    this.gridOptionsSummary.rowData = this.summaryOfChanges;

    this.sendNotification = true;

    this.initialize('join_as_coteacher').subscribe(() => this.generateReplacements(this.validClasses));

  }
  goForward(stepper: MatStepper): void {
    this._firebaseAnalytics.sendEvent((LscEvents.lscEvents.explorer[ADD_SELF_TO_CLASSES] as StepAnalyticsEvent)[stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
    stepper.next();
  }

  addSelf(): void {

    const currentUserEmail = this._authSvc.getCurrentUser().profile.primaryEmail;

    const clsDetails = this.validClasses.map(selection => ({
        clsId: selection.id,
        toAdd: [
          currentUserEmail
        ]
      }));

    if (this.endDate) {
      this.endDate = moment(moment(this.endDate).format('YYYY-MM-DD') + ' ' + this.endTime, 'YYYY-MM-DD HH:mm a');
    }

    const updatedData = {
      classrooms: clsDetails,
      actionType: 'join_as_coteacher',
      sendNotification: this.sendNotification,
      customerActionTemplate: this.customerActionTemplate,
      userActionTemplate: this.joinAsCoteacher,
      replyEmail: this.notificationEmail.value,
      accessTime: (this.endDate ? this.endDate : this.selectedAccessTime)
    };

    const analyticsEvent = {
      action: 'selfAddCoTeacher',
      properties: {
        category: 'admin',
        label: 'end'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);
    this._firebaseAnalytics.sendEvent((LscEvents.lscEvents.explorer[ADD_SELF_TO_CLASSES] as StepAnalyticsEvent)[ADD_SELF_TO_CLASSES_STEPPER_STEPS-1][StepAnalyticsEventEnum.completed]);

    this.dialogRef.close(updatedData);
  }

  onGridReadySummary(params: any): void {
    params.api.sizeColumnsToFit();
  }

  cancel(stepper: MatStepper): void {
    this._firebaseAnalytics.sendEvent((LscEvents.lscEvents.explorer[ADD_SELF_TO_CLASSES] as StepAnalyticsEvent)[stepper.selectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close();
  }

}
