import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';

import { ConfirmDialogComponent } from 'app/core/components/confirm-dialog/confirm-dialog.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  GridOptions,
  GridReadyEvent,
  ValueGetterParams,
} from '@ag-grid-community/core';

import {
  AgGridProfileSummaryCellRendererComponent,
  GridDataService,
} from '@cdw-ae/little-sis-for-classroom-ag-grid';
import { LscEvents, StepAnalyticsEvent, StepAnalyticsEventEnum } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { AnalyticsEvent, FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {
  ArchiveMetaItem,
  ArchiveQueryData,
  ClassroomService,
  NotificationData,
} from '../../../../../services/classroom.service';
import {
  CustomerFirestore,
  CustomerSettings,
  FirestoreCustomerService,
} from '../../../../../services/firestore-customer.service';
import {
  School,
  SchoolService,
} from '../../../../../services/schools.service';
import { UserService } from '../../../../../services/user.service';
import { UtilService } from '../../../../../services/util.service';
import { ApiLookupService } from '../../../../auth/services/api-lookup/api-lookup.service';
import { AuthService } from '../../../../auth/services/Auth/auth.service';

export const CLASSES_BULK_ACTION_STEPPER_LENGTH = 4;
@Component({
  selector: 'app-classes-bulk-action',
  templateUrl: './classes-bulk-action.component.html',
  styleUrls: ['./classes-bulk-action.component.scss']
})
export class ClassesBulkActionComponent implements OnInit {

  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @Input() action: string;
  @Input() searchTitle: string;
  @Input() function: string;
  @Input() selectResultsTitle: string;
  @Input() finalStepTitle: string;
  @Input() finalStepConfirmation: string;
  @Input() hideFilterOptions?: boolean;
  @Output() complete: EventEmitter<boolean> = new EventEmitter();
  @Output() stepperSelectedIndex: EventEmitter<number> = new EventEmitter();

  school$: Observable<School[]>;
  archiveMeta: ArchiveMetaItem[] = []; // Not the best name because archiveMeta can apply to archive and delete
  loading = false;
  validatingTeachers = false;
  validatingBulkActionRequest = false;

  // Variables relating to setting primary teacher emails
  emails: string;
  teachers = [];
  invalidCount: number;
  valid: boolean;
  copyFrom: number;
  suspendedTeacherCount: number;

  // Variables relating to checking/unchecking the selection
  selected: ArchiveMetaItem[] = [];

  // Variables relating to the email notification
  requireNotification = false;
  sendNotification = true;
  allowNotification = true;
  customerActionTemplate: string;
  userActionTemplate = '<p>If you have any questions, please contact me.</p><p>{{myName}}</p>';
  defaultUserTemplate = '<p>If you have any questions, please contact me.</p><p>{{myName}}</p>';
  templateReplacements: unknown;
  tokens: unknown[] = [
    { id: '1', toolTip: 'Insert teacher name', icon: 'person_outline', insertString: '{{teacherName}}' },
    { id: '2', toolTip: 'Insert class list', icon: 'assignment', insertString: '{{classList}}'},
    { id: '3', toolTip: 'Insert your name', icon: 'account_box', insertString: '{{myName}}'}
  ];
  notificationEmail = new FormControl('', [
    Validators.email
  ]);
  defaultTMPL: string; // XXX: Ger - Not sure this is needed. It will always be undefined...?

  // Variables relating to the grid
  columnDefs = [
    {
      headerName: 'Primary Teacher',
      field: 'primaryTeacher',
      autoHeight: true,
      width: 200,
      cellRenderer: 'profileSummaryCellRenderer',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      suppressMovable: true,
      filterParams: {
        filterOptions: [ 'contains' ],
        textCustomComparator: (filter: string, gridValue: any, filterText: string): boolean => {
          // LSC-830 - Move this filter function into a separate file. Call it something like profileFilterComparator. Or better still, set the whole filterParams object as we need the valueGetter and the textCustomerComparator too (although structure of valueGetter can vary depending on underlying data structure).

          const filterTextLowerCase = filterText.toLowerCase();
          const name = gridValue.name.toString().toLowerCase();
          const email = gridValue.primaryEmail.toString().toLowerCase();

          switch (filter) {
            case 'contains':
              return name.indexOf(filterTextLowerCase) >= 0 || email.indexOf(filterTextLowerCase) >= 0;
            case 'notContains':
              // return valueLowerCase.indexOf(filterTextLowerCase) === -1;
              return false;
            case 'equals':
              // return valueLowerCase === filterTextLowerCase;
              return false;
            case 'notEqual':
              // return valueLowerCase != filterTextLowerCase;
              return false;
            case 'startsWith':
              // return valueLowerCase.indexOf(filterTextLowerCase) === 0;
              return false;
            case 'endsWith':
              // var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
              // return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
              return false;
            default:
              // should never happen
              console.warn('invalid filter type ' + filter);
              return false;
            }
        },
        textFormatter: (value) => value, // Seems a bit of a hack but the textFormatter function is needed so the value is not stringified to '[Object: object]'
        valueGetter: (params: ValueGetterParams): any => ({
            name: params.data.primaryTeacher.name,
            primaryEmail: params.data.primaryTeacher.primaryEmail
          }),
      },
      comparator: (a, b) => {
        // As we have full list of rows in client we can perform client-side sorting on the primaryEmail properties
        if (a.primaryEmail > b.primaryEmail) {
          return 1;
        } else if (a.primaryEmail < b.primaryEmail) {
          return -1;
        } else {
          return 0;
        }
      }
    },
    {
      headerName: 'Course',
      field: 'classroom.name',
      width: 200,
      filter: 'agTextColumnFilter',
      suppressMovable: true,
      suppressMenu: true,
      valueFormatter: (cell) => {
        if (cell.data.classroom.section) {
          return cell.value + ' - ' + cell.data.classroom.section;
        }
        return cell.value;
      },
    },
    {
      headerName: 'Created',
      field: 'classroom.creationTime',
      width: 120,
      cellClass: 'text-center',
      filter: 'agDateColumnFilter',
      filterParams: {
        defaultOption: 'lessThan',
        comparator: GridDataService.customDateComparator
      },
      sortable: true,
      suppressMenu: true,
      suppressMovable: true,
      hide: false,
      valueFormatter: (cell) => moment(cell.value).format('MM/DD/YY HH:mm'),
    },
    {
      headerName: 'Updated',
      field: 'classroom.updateTime',
      width: 120,
      cellClass: 'text-center',
      filter: 'agDateColumnFilter',
      suppressMovable: true,
      filterParams: {
        defaultOption: 'lessThan',
        comparator: GridDataService.customDateComparator
      },
      sortable: true,
      suppressMenu: true,
      hide: false,
      valueFormatter: (cell) => moment(cell.value).format('MM/DD/YY HH:mm'),
    }
  ];

  frameworkComponents: {[s: string]: any} = {
    profileSummaryCellRenderer: AgGridProfileSummaryCellRendererComponent
  };

  gridOptions: GridOptions = {
    columnDefs: this.columnDefs,
    components: this.frameworkComponents,

    rowModelType: 'clientSide',
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    rowBuffer: 100,
    cacheBlockSize: 100,
    maxBlocksInCache: 2,
    rowData: this.archiveMeta,
    defaultColDef: {
      filter: true,
      filterParams: {
        debounceMs: 500,
        newRowsAction: 'keep'
      },
      floatingFilter: true,
      hide: false,
      resizable: true,
      sortable: true
    },
  };

  lspLicensed = false;
  onlyLittleSISPremiumClasses = false;
  allOrphaned = false;

  private onDestroy$: Subject<void> = new Subject<void>();
  private emailParse = {
    notEmails: [],
    emails: [],
  };
  
  private static getFormattedDateString(input?: Date | moment.Moment): string {
    if (moment.isMoment(input)) {
      return (input as moment.Moment).format('YYYY-MM-DD HH:mm:ss');
    }
    if (input instanceof Date) {
      return input.toISOString()?.replace('T', ' ')?.split('.')?.[0];
    }
    return '';
  }

  constructor(
    public apiLookup: ApiLookupService,
    private _authService: AuthService,
    protected _firestoreCustomerService: FirestoreCustomerService,
    private _classroomService: ClassroomService,
    public dialog: MatDialog,
    private _schoolService: SchoolService,
    public snackBar: MatSnackBar,
    public userService: UserService,
    private _firebaseAnalytics: FirebaseAnalyticsService,

    private gridDataService: GridDataService
  ) {

    this._firestoreCustomerService.getCustomer()
      .pipe(
        takeUntil(this.onDestroy$),
        map((customer: CustomerFirestore) => {
          const settings = Object.assign({
            forceTeacherNotification: false
          }, customer.settings);
          return settings;
        })
      )
      .subscribe((settings: CustomerSettings) => {
        this.requireNotification = settings.forceTeacherNotification || false;
      });

      this.lspLicensed = true;

  }

  onGridReady(params: GridReadyEvent) {

    params.api.sizeColumnsToFit();
    // params.api.selectAll();

  }

  async ngOnInit() {
    this.stepperSelectedIndex.emit(0);
    await this.initialiseNotifications();

    this.school$ = this._schoolService.getAccessRestrictedSchools()
    .pipe(
      takeUntil(this.onDestroy$),
      map(schools => Object.keys(schools).reduce((schoolsArray: School[], schoolId) => {
          schoolsArray.push(schools[schoolId]);
          return schoolsArray;
        }, []))
    );

    this.generateReplacements(this.archiveMeta);
  }

  // LSC-830 - Start of code relating to primary teacher selection (refactor to separate component)
  validateEmail(form: NgForm) {
    this.validatingTeachers = true;
    let emails = form.value.teacherEmails;

    if (this.emails === '') {
      return;
    }

    const rows = emails.split('\n');

    this.emailParse.notEmails = rows.filter( emailEntries => {
      if (!emailEntries.match(/[\w'\.-]+@[\w\.-]+\.\w+/g)) {
        this.validatingTeachers = false;
        return emailEntries;
      }
    });

    const actualEmails = emails.match(/[\w'\.-]+@[\w\.-]+\.\w+/g);

    this.userService.getUserFromEmails(actualEmails).subscribe(  results => {
      this.validatingTeachers = false;
      emails = '';
      emails = results.invalidUsers.join('\n');

      //bind the emails which are not valid email formats
      if (emails !== '') {
        emails = emails + '\n' + this.emailParse.notEmails.join('\n');
      } else if (this.emailParse.notEmails){
        emails = this.emailParse.notEmails.join('\n');
      }

      //using valid email in form of array
      this.teachers = _.uniqBy(this.teachers.concat(results.validUsers), (user) => user.gId);

      form.controls.teacherEmails.setValue(emails);

      this.invalidCount = results.invalidUsers.length + this.emailParse.notEmails.length;

    });

  }


  removeAllEmails() {

    const dialogData = {
      message: 'Are you sure you want to remove all selected accounts?',
      okBtnLabel: 'Confirm',
      cancelBtnLabel: 'Cancel'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'ait-little-sis-panel',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.teachers.length = 0;
      }

    });

  }

  removeValidEmail(user) {
    this.teachers = this.teachers.filter( emailEntries => {
      if(emailEntries.primaryEmail !== user.primaryEmail) {
        return emailEntries;
      }
    });
  }
  // LSC-830 - End of code relating to primary teacher selection

  onSelectionChanged(event) {
    const rows = event.api.getSelectedNodes();

    if (!rows.length) {
      this.clearSelectedItems();
    } else {
      this.selected = _.map(rows, 'data');
    }
  }

  clearSelectedItems() {
    const rows = this.gridOptions.api.getSelectedNodes();

    if (rows.length) {
      this.gridOptions.api.deselectAll();
    }

    this.selected.length = 0;
  }

  onSearchClasses(form: NgForm) {
    this.loading = true;

    const statuses = [];
    if (this.function === 'activate') {
      statuses.push('provisioned');
    } else if (this.function === 'archive') {
      statuses.push('active');
    } else if (this.function === 'delete') {
      statuses.push('provisioned', 'declined');
    } else if (this.function === 'remove_student') {
      statuses.push('archived');
    }

    const query: ArchiveQueryData = {
      createdBefore: ClassesBulkActionComponent.getFormattedDateString(form.value.olderThanDate),
      schools: form?.value?.schools ? form.value.schools.map(school => school.id) : [],
      teachers: this.teachers?.map(teacher => teacher.gId),
      status: statuses,
      onlyLSPManaged: this.onlyLittleSISPremiumClasses,
      onlySuspendedPrimaryTeachers: this.function === 'archiveSuspended',
    };

    this._classroomService.getClassesFromBulkActionCriteria(query)
      .pipe(
        tap(() => this.loading = false)
      )
      .subscribe((archiveMeta: ArchiveMetaItem[]) => {
        this.archiveMeta.length = 0;
        this.archiveMeta.push(...archiveMeta);
      });
      this.goForward();
  }

  async onClassesSelected() {
    let valid = true;
    if (this.function === 'archiveSuspended') {
      valid = await this.validateBulkActionRequest('archiveSuspendedPrimaryTeacherClasses');
    }
    if (valid && !this.invalidCount) {
      this.generateReplacements(this.selected);
      this.goForward();
    }
  }

  async validateBulkActionRequest(action: string) {
    this.validatingBulkActionRequest = true;
    this.suspendedTeacherCount = 0;
    let valid = false;
    const classIds = this.selected.map(selected => selected?.classroom?.id);
    const teacherIds = this.selected.map(selected => selected?.primaryTeacher?.gId);
    try {
      const response = await this._classroomService.validateBulkActionRequest({ action, classIds, teacherIds }).toPromise();
      valid = response?.success;
      this.invalidCount = response?.classrooms?.filter((classroom) => !classroom?.valid)?.length || 0;
      const classesLookup = response?.classrooms?.reduce((accumulator, classroom) => ({ ...accumulator, [classroom.id]: classroom }), {});
      const selectedRows = this.gridOptions.api.getSelectedNodes();
      selectedRows.forEach(row => {
        if (classesLookup?.[row?.data?.classroom?.id]?.valid === false) {
          row.setSelected(false);
          row.setRowSelectable(false);
        }
      });
      if (!valid && !this.invalidCount) {
        this.snackBar.open(`Unable to validate your selection. Please try again...`, 'Ok', {duration: 2000});
      } else if (this.invalidCount) {
        this.snackBar.open(`Some classes have had updates elsewhere and were removed from your selection...`, 'Ok', {duration: 2000});
      }
    } catch(error: any) {
      this.snackBar.open(
        'An unexpected error occurred while attempting to validate the classes you would like to update. Please try again',
        'Ok',
        {duration: 2000}
      );
      console.error(error?.message);
    }
    if (this.function === 'archiveSuspended') {
      this.updateSuspendedTeacherCount();
    }
    this.validatingBulkActionRequest = false;
    return valid;
  }

  updateSuspendedTeacherCount() {
    const suspendedTeachersLookup = this.selected.reduce((accumulator: { [teacherName: string]: boolean }, row: ArchiveMetaItem) => {
      accumulator[row.primaryTeacher.gId] = true;
      return accumulator;
    }, {});
    this.suspendedTeacherCount = Object.keys(suspendedTeachersLookup).length;
  }

  // LSC-830 - Start of code relating to notification email (Consider refactoring into its own function)
  templateChanged(event) {
    if (event[this.action]) {
      this.userActionTemplate = event[this.action];
    }
    // for (const key in event) {
    //   this[key] = event[key];
    // }
  }

  generateReplacements(archiveMeta: ArchiveMetaItem[]) {
    const firstNonOrphanedTeacher = archiveMeta.find((teacher) =>
        teacher.primaryTeacher.gId &&
        (teacher.primaryTeacher.primaryEmail !== 'Unknown Email')
      );
    if (firstNonOrphanedTeacher) {
      this.allOrphaned = false;
      const archiveMetaFirstTeacher = archiveMeta.filter((teacher) =>
        teacher.primaryTeacher.gId === firstNonOrphanedTeacher.primaryTeacher.gId
      );
      const firstTeacherClassList = archiveMetaFirstTeacher.reduce((accum, curr) => {
        accum += `<li><a href='${GridDataService.validateAlternateLink(curr.classroom.alternateLink)}' target="_blank">${curr.classroom.name}`;

        if (curr.classroom.section) {
          accum += ' [' + curr.classroom.section + ']';
        }

        accum += '</a></li>';
        return accum;
      }, '');

      this.templateReplacements =  {
        '{{teacherName}}': (archiveMetaFirstTeacher.length ? archiveMetaFirstTeacher[0].primaryTeacher.name : ''),
        '{{classList}}': '<ul>' + firstTeacherClassList + '</ul>',
        '{{hasPlural}}': (archiveMetaFirstTeacher.length === 1 ? 'has' : 'have'),
        '{{wasPlural}}': (archiveMetaFirstTeacher.length === 1 ? 'was' : 'were'),
        '{{thisPlural}}': (archiveMetaFirstTeacher.length === 1 ? 'this class' : 'these classes'),
        '{{classPlural}}': (archiveMetaFirstTeacher.length > 1 ? 'Google Classroom classes' : 'Google Classroom class'),
        '{{classCount}}': archiveMetaFirstTeacher.length,
        '{{userName}}': this._authService.getCurrentUser().profile.displayName,
        '{{myName}}': this._authService.getCurrentUser().profile.displayName,
        '{{actingUserName}}': this._authService.getCurrentUser().profile.displayName,
        '{{actingUserProfileImageUrl}}': this._authService.getCurrentUser().profile.photoUrl || UtilService.defaultPhotoUrl
      };
    } else {
      this.allOrphaned = true;
      this.templateReplacements = {};
    }
  }

  async initialiseNotifications() {
    const emailTemplate = this.function === 'remove_student' ? this.function : this.action;
    await this.apiLookup.getCustomerSettings(emailTemplate).subscribe(results => {
      if (results) {
        this.customerActionTemplate = results.customerActionTemplate as string || this.defaultTMPL;
        this.userActionTemplate = results.userActionTemplate as string || this.defaultUserTemplate;

        if (!this._authService.isSuperAdmin()) {
          this.notificationEmail.setValue(this._authService.getCurrentUser().profile.primaryEmail);
          this.notificationEmail.disable();
        } else {
          this.notificationEmail.setValue(results.replyEmail);
        }

      } else {

        this.customerActionTemplate = this.defaultTMPL;
        this.userActionTemplate = this.defaultUserTemplate;
      }
    });
  }
  // LSC-830 - End of code relating to notification email


  onRunJob() {
    if (this.function === 'archiveSuspended') {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.archiveClassesWithSuspendedTeachersActioned as AnalyticsEvent);
    }

    this.loading = true;

    const classesByTeacher = this.selected.reduce((aggregateByTeacher, classData) => {
      if (!aggregateByTeacher[classData.primaryTeacher.gId]) {
        aggregateByTeacher[classData.primaryTeacher.gId] = {
          primaryTeacherId: classData.primaryTeacher.gId,
          courses: []
        };
      }
      aggregateByTeacher[classData.primaryTeacher.gId].courses.push(classData.classroom.id);
      return aggregateByTeacher;
    }, {});

    const notificationData: NotificationData = {
      replyEmail: this.notificationEmail.value,
      sendNotification: this.sendNotification,
      customerActionTemplate: this.customerActionTemplate,
      userActionTemplate: this.userActionTemplate,
    };

    of(this.function === 'remove_student' ? this.function : this.action)
    .pipe(
      switchMap((action) => {
        if (action === 'activate') {
          return this._classroomService.bulkActivateClasses(Object.values(classesByTeacher), notificationData);
        } else if (action === 'archive') {
          return this._classroomService.bulkArchiveClasses(Object.values(classesByTeacher), notificationData);
        } else if (action === 'delete') {
          return this._classroomService.bulkDeleteClasses(Object.values(classesByTeacher), notificationData);
        } else if (action === 'remove_student') { // shouldn't this be 'clear'? I thought that 'remove_student' was the function not the action
          return this._classroomService.bulkClearRosters(Object.values(classesByTeacher), notificationData, this.selected.length);
        }
      }),
      tap(() => this.loading = false)
    ).subscribe(
      () => {
        this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[this.action][this.stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
        this.snackBar.open(`The classes are being processed.`, 'Ok', {duration: 2000});
        this.complete.emit(true);
      },
      (error) => {
        this._firebaseAnalytics.sendEvent(((LscEvents.lscEvents.explorer[this.action] as StepAnalyticsEvent).error as AnalyticsEvent));
        this.snackBar.open(`The classes cannot be processed at the moment. ${error.message}`, 'Ok', {duration: 10000});
      }
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getDefaultPhotoUrl() {
    return UtilService.defaultPhotoUrl;
  }

  goForward() {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[this.action][this.stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
    this.stepper.next();
    this.stepperSelectedIndex.emit(this.stepper.selectedIndex);
  }

  goBack(): number {
    this.stepper.previous();
    if (this.stepper.selectedIndex === 0) {
      this.selected = [];
    }
    return this.stepper.selectedIndex;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
