import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppNavigationService } from '@amplabs/components/navigation/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InstallationService } from 'app/core/services/installation/installation.service';
import { InstallState } from 'app/modules/common/models/InstallState';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { AuthService } from '../../../app/modules/auth/services/Auth/auth.service';
import { CustomerService } from '../../../app/modules/common/services/customer.service';
import { LicenseState } from '../../../app/modules/licensing/services/licensing.interfaces';
import { LittlesisLicensingService } from '../../../app/modules/licensing/services/littlesis-licensing.service';
import { InstallAdditionalScopeDialogComponent } from '../install-additional-scope-dialog/install-additional-scope-dialog.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector     : 'fuse-navigation',
    templateUrl  : './navigation.component.html',
    styleUrls    : ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppNavigationComponent implements OnInit, OnDestroy
{
  @Input()
  layout = 'vertical';

  @Input()
  navigation: any;

  @Input()
  disabled: boolean;

  displayGettingStartedAtRoot: boolean;

  badges: {
    license: boolean;
  };

  licenseState: LicenseState;

  scopesModalIsOpen: boolean;

  skippedRoutesForNotification: string[];

  // Private
  // private _unsubscribeAll: Subject<any>;

  private onDestroy$: Subject<void> = new Subject<void>();

  private user;

  private lastSettingsStringified = '';

  /**
   * Constructor
   */
  constructor(
      private _authSvc: AuthService,
      private _customerSvc: CustomerService,
      private _featureToggleService: FeatureToggleService,
      private _appNavigationService: AppNavigationService,
      private _licensingSvc: LittlesisLicensingService,
      public dialog: MatDialog,
      private auth: AuthService,
      private _installationService: InstallationService,
      private router: Router
  ) {
    // Set the private defaults
    this.badges = {
      license: false,
    };

    this.skippedRoutesForNotification = [
      '/product/terms',
      '/product/privacy',
      '/admin/getting-started',
      '/admin/settings',
    ];

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._authSvc.getActiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(usr => {

      this.user = usr;

      if (this.navigation) {
        this.navigation = this.setNavVisibility(this.navigation);
        this._reviewParentVisibility();
      }

    });

    this._customerSvc.getSettings().pipe(takeUntil(this.onDestroy$)).subscribe(settings => {
      if (settings.installState && this.user?.auth) {
        this.displayGettingStartedAtRoot = (this.user.auth.role_key === 'sa') && (
          !settings.installState.users
          || !settings.installState.classrooms
          || !settings.installState.metadata
        );
        const settingsStringified = JSON.stringify(settings);
        if (this.skippedRoutesForNotification.indexOf(this.router.url) === -1 && settingsStringified !== this.lastSettingsStringified) {
          this.lastSettingsStringified = settingsStringified;
          this.promptForAdditionalScopesIfRequired(settings.installState);
        }
      } else {
        this.displayGettingStartedAtRoot = false;
      }
    });

    this._customerSvc.getCurrentCustomer().pipe(takeUntil(this.onDestroy$)).subscribe(() => {

      if (this.navigation) {
        this.navigation = this.setNavVisibility(this.navigation);
        this._reviewParentVisibility();
      }

    });

    this._licensingSvc.checkLicense().pipe(takeUntil(this.onDestroy$)).subscribe((licenseState) => {

      this.licenseState = licenseState;
      this.badges.license = (licenseState.expiresIn < 30);
      this.navigation = this._setBadgeVisibility(this.navigation);

    });


    // Load the navigation either from the input or from the service
    this.navigation = this.setNavVisibility(this.navigation) || this.setNavVisibility(this._appNavigationService.getCurrentNavigation());

    this._reviewParentVisibility();

    // Subscribe to the current navigation changes
    this._appNavigationService.onNavigationChanged
        // .pipe(takeUntil(this._unsubscribeAll))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
            this.navigation = this.setNavVisibility(this._appNavigationService.getCurrentNavigation());
            this._reviewParentVisibility();
        });
  }

  promptForAdditionalScopesIfRequired(installState: InstallState): void {
    let designatedAdminAccess = Promise.resolve(true);
    if (installState.designatedAdmin) {
      designatedAdminAccess = this._installationService.verifyDesignatedAdminAccess({ notify: false })
          .then((result) => (result.success || (result.error && result.error === 'No active classrooms for cw registration test')));
    }

    let clientInstalled = Promise.resolve(true);
    if (installState.client) {
        clientInstalled = this._installationService.verifyAccess({ notify: false, useCache: false })
          .then((result) =>  result.success);
    }

    Promise.all([designatedAdminAccess, clientInstalled])
      .then(([designatedAdminAccessResult, clientInstalledResult]) => {
          if (!designatedAdminAccessResult || !clientInstalledResult) {
            // Open dialog
            this.showAdditionalScopesModal(this.auth.isSuperAdmin());
          }
      });
  }

  showAdditionalScopesModal(isAdmin: boolean): void {
    if (this.scopesModalIsOpen) {
      return;
    }

    this.scopesModalIsOpen = true;
    const dialogRef = this.dialog.open( InstallAdditionalScopeDialogComponent, {
      disableClose: true,
      panelClass: 'ait-little-sis-panel',
      data: {isAdmin},
      width: '700px',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.scopesModalIsOpen = false;
    });
  }

  _setBadgeVisibility(node) {

    if (!node) {
      return node;
    }

    if (Array.isArray(node)) {

      _.each(node, rte => {

        if (rte.children && rte.children.length) {
          rte.children = this._setBadgeVisibility(rte.children);
        }

        if (rte.showBadge && this.badges[rte.showBadge]) {

          rte.badge = {
            icon: 'warning',
          };

          if (this.licenseState.expiresIn > 10) {
            rte.badge.fg = '#ffed79';
          } else {
            rte.badge.fg = '#ff2e2c';
          }

          if (this.licenseState.expiresIn > 1) {
            rte.badge.tooltip = `Your Little SIS license will expire in ${this.licenseState.expiresIn} days`;
          } else if (this.licenseState.expiresIn === 2) {
            rte.badge.tooltip = `Your Little SIS license expires tomorrow`;
          } else if (this.licenseState.expiresIn === 1) {
            rte.badge.tooltip = `Your Little SIS license expires today`;
          }

        } else {
          delete rte.badge;
        }

      });

    } else {

      if (node.showBadge && this.badges[node.showBadge]) { }

    }

    return node;

  }

  setNavVisibility(node) {

    if (!node) {
      return node;
    }

    if (Array.isArray(node)) {

      _.each(node, rte => {

        if (rte.children && rte.children.length) {
          rte.children = this.setNavVisibility(rte.children);
        }
        if (rte.feature && !this._featureToggleService.isActive(rte.feature)) {
          // Refactor! This logic is duplicated in _reviewParentVisibility
          rte.hidden = true;
          return rte.hidden;
        }

        if (rte.requiredPermissions && this.user?.auth?.permissionMap) {
          // Refactor! This logic is duplicated in _reviewParentVisibility
          rte.hidden = true;

          if (this.user.auth.role_key === 'sa') {
            if (rte.id === 'gettingStartedRepeat') {
              rte.hidden = this.displayGettingStartedAtRoot;
            }
            else {
              rte.hidden = false;
            }
          } else {
            rte.requiredPermissions.forEach((p) => {
              if (this.user.auth.permissionMap[p]) {
                rte.hidden = false;
              } else {
                rte.hidden = true;
              }
            });
          }
        } else {
          rte.hidden = !!(rte.requiredPermissions && rte.requiredPermissions.length);
        }

      });

    } else {
      if (node.requiredPermissions && this.user?.auth?.permissionMap) {

        node.hidden = true;

        if (this.user.auth.role_key === 'sa') {
          node.hidden = false;
        } else {
          node.requiredPermissions.forEach((p) => {
            if (this.user.auth.permissionMap[p]) {
              node.hidden = false;
            }
          });
        }
      } else {
        node.hidden = !!(node.requiredPermissions && node.requiredPermissions.length);
      }
    }

    return node;

  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private _reviewParentVisibility() {

    // Show/hide the parent menu based on child menu visibility
    this.navigation.forEach(node => {
      const hiddenChild = [];
      const showChild = [];
      if(node.children) {
        node.children.forEach(childNode =>{
          if(childNode.hidden) {
            hiddenChild.push({hidden:'true',id:childNode.id});
          }
          else {
            showChild.push({hidden:'false',id:childNode.id});
          }
        });
      }
      if (hiddenChild.length !== 0 && showChild.length === 0 ) {
        node.hidden = true;
      } else if (node.id === 'gettingStarted') {
        node.hidden = !this.displayGettingStartedAtRoot;
      } else if (node.feature && !this._featureToggleService.isActive(node.feature)) {
        // Refactor! This logic is duplicated in setNavVisibility
        node.hidden = true;
      } else if (node.requiredPermissions && this.user?.auth?.permissionMap) {
        // Refactor! This logic is duplicated in setNavVisibility
        node.hidden = true;
        if (this.user.auth.role_key === 'sa') {
          if (node.id === 'gettingStartedRepeat') {
            node.hidden = this.displayGettingStartedAtRoot;
          }
          else {
            node.hidden = false;
          }
        } else {
          node.requiredPermissions.forEach((p) => {
            if (this.user.auth.permissionMap[p]) {
              node.hidden = false;
            } else {
              node.hidden = true;
            }
          });
        }
      } else {
        node.hidden = false;
      }
    });

  }

}
