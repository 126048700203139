import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';

declare let gapi: any;
declare let google: any; // For Google Identity Services

@Injectable()
export class AdminSDKService {

  private AdminSDKDiscoveryDoc = 'https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest';
  private Required_Scopes = ['https://www.googleapis.com/auth/admin.directory.user.readonly'];
  private tokenClient: any; // GIS token client

  constructor() {
    this.initializeTokenClient();
  }

  // Keep loadClient method as requested
  public async loadClient() {
    gapi.load('client',  {
      callback: () => this.initClient(),
      onerror: () => console.error('Failed to Load GAPI'),
      timeout: 5000, // 5 seconds.
      ontimeout: () => console.error('gapi.client could not load in a timely manner!')
    });
  }

  public async initClient() {
    try {
      await gapi.client.init({
        discoveryDocs: [this.AdminSDKDiscoveryDoc],
      });
    } catch (error) {
      console.error(`Unable to init Directory API service`, error);
    }
  }

  public requestAccessToken() {
    this.tokenClient.requestAccessToken();
  }

  // eslint-disable-next-line class-methods-use-this
  async getDirectoryUser(userEmail: string, authToken: string): Promise<any> {
    try {
      // Set the access token before making the request
      gapi.client.setToken({ access_token: authToken });

      const response = await gapi.client.directory.users.get({
        userKey: userEmail,
        fields: '*',
      });
      return response.result;
    } catch (error) {
      console.error('Failed to get directory user:', error);
      throw error;
    }
  }

  private initializeTokenClient() {
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: environment.web_client_id,
      scope: this.Required_Scopes.join(' '),
      prompt: 'consent',
      callback: (tokenResponse: any) => {
        if (tokenResponse.error) {
          console.error('Token request failed:', tokenResponse.error);
        } else {
          gapi.client.setToken({ access_token: tokenResponse.access_token });
        }
      },
    });
  }
}
