import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import { ClassActions, Course } from '../../../../../services/classroom.service';
import { ApiLookupService } from '../../../../auth/services/api-lookup/api-lookup.service';
import { AuthService } from '../../../../auth/services/Auth/auth.service';

import { LscEvents, StepAnalyticsEventEnum } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { FirestoreCustomerService } from '../../../../../services/firestore-customer.service';
import { GenericBulkActionComponent } from '../generic-bulk-action.component';

export function validateAnswer(input: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const answer = (input.toString() === control.value);
    return answer ? null : {validateAnswer: {value: control.value}};
  };
}

export const DELETE_CLASSES_STEPPER_STEPS = 3;
export const DELETE_CLASSES = 'explorerDeleteClasses';

@Component({
  selector: 'app-bulk-delete-classes-dialog',
  templateUrl: './bulk-deleteClasses-dialog.html',
  styleUrls: ['./bulk-deleteClasses-dialog.scss'],
})

export class BulkDeleteClassesDialogComponent extends GenericBulkActionComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  busy = false;

  confirmationQuestion = new FormControl('', [
    Validators.required,
    validateAnswer(this.data.selectedClasses.length),
  ]);

  selectedItems: string[] = [];
  selectedClasses: Course[] = [];

  grids: {
    classes: {
      height: number;
    };
  };

  constructor( public apiLookup: ApiLookupService,
               public dialogRef: MatDialogRef<BulkDeleteClassesDialogComponent>,
               protected _authSvc: AuthService,
               protected _firestoreCustomerService: FirestoreCustomerService,
               private _firebaseAnalytics: FirebaseAnalyticsService,
               @Inject(MAT_DIALOG_DATA) public data: ClassActions ) {

    super(apiLookup, data, _authSvc, _firestoreCustomerService);

    this.grids = {
      classes: {
        height: Math.min(80 + this.data.selectedClasses.length * 60, 300),
      },
    };

  }

  goForward(stepper: MatStepper) {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[DELETE_CLASSES][stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
    super.goForward(stepper);
  }

  ngOnInit() {

    this.gridOptions.rowData = this.data.selectedClasses;
    this.sendNotification = true;

    this.initialize('delete').subscribe(() => this.generateReplacements(this.data.selectedClasses));
  }

  deleteClasses() {

    const classroomIds = this.data.selectedClasses.map(cls => cls.id);

    const updatedData = {
      ids: classroomIds,
      actionType: 'delete',
      sendNotification: this.sendNotification,
      customerActionTemplate: this.customerActionTemplate,
      userActionTemplate: this.userActionTemplate,
      replyEmail: this.notificationEmail.value,
    };
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[DELETE_CLASSES][DELETE_CLASSES_STEPPER_STEPS-1][StepAnalyticsEventEnum.completed]);

    this.dialogRef.close(updatedData);
  }

  cancel(stepper: MatStepper): void {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[DELETE_CLASSES][stepper.selectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close();
  }
}

