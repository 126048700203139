import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { CustomerFirestore, FeatureLookup, FirestoreCustomerService } from './firestore-customer.service';


@Injectable({ providedIn: 'root' })

export class FeatureToggleService {
  private customer: CustomerFirestore;
  private environmentFeatureToggles = environment.features;

  constructor(
    private customerService: FirestoreCustomerService
  ) {
    this.customerService.getCustomer().subscribe(customer => {
      this.customer = customer;
    });
  }

  private static getFeatureValue(featureName: string, featureLookup: FeatureLookup): 'beta' | boolean | undefined {
    const featureParts: string[] = featureName.split('.');
    const featureValue = featureParts.reduce(
      (path, c) => path?.[c] as FeatureLookup,
      featureLookup
    ) as unknown as 'beta' | boolean | undefined;
    return featureValue;
  }

  isActiveForEnvironment(featureName: string): 'beta' | boolean {
    // Specifically checks the environment toggle, not the customer toggle. It's useful when we want the missing feature for a customer to default as on (e.g. the feature is not toggleable at a customer level)
    const featureValue = FeatureToggleService.getFeatureValue(featureName, this.environmentFeatureToggles as unknown as FeatureLookup);
    if (featureValue !== 'beta' && featureValue !== true) {
      return false;
    }
    return featureValue;
  }

  isActive(featureName: string): boolean {
    const isActiveForEnvironment = this.isActiveForEnvironment(featureName);
    if (isActiveForEnvironment !== 'beta') {
      return isActiveForEnvironment;
    }
    if (this.customer && this.customer.features) {
      const featureValue = FeatureToggleService.getFeatureValue(featureName, this.customer.features);
      return !!featureValue;
    }
    return true; // TODO: Fix this as hardset to prevent refresh issue...
  }

}
