import { GridOptions } from '@ag-grid-community/core';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, takeUntil } from 'rxjs';

import { AgGridState } from '@cdw-ae/angular-ui-cdw-ag-grid';
import { exportGrid, getGridStateFromAgGridOptions, LSCContentType, ProjectName } from '@cdw-ae/components-export-wizard';

import { AuditsService } from '../../../services/Audits/audits.service';
import { AuthService, User } from '../../auth/services/Auth/auth.service';
import { SheetsService } from '../../exports/services/sheets.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GridExportsService implements OnDestroy {
  private readonly projectName: ProjectName = 'LSC';

  private exportEndpoint: string = environment.cloudRunInstances.exports;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private getAccessToken: () => Promise<{ access_token: string }>;

  private onDestroy$: Subject<void> = new Subject<void>();

  private user: User;

  constructor(
    private authService: AuthService,
    private sheetsService: SheetsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private auditService: AuditsService
  ) {
    this.getAccessToken = async () => {
      // console.log('called getAccessToken');
      const ac = await this.sheetsService.authCheck();
      // console.log(ac);
      return ac;
    };
    this.authService.getActiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(user => {
      this.user = user;
    });
  }

  public static getGridState(gridOptions: GridOptions, uniqueColumnName = 'id'): AgGridState {
    return gridOptions?.api && gridOptions?.columnApi ?
      getGridStateFromAgGridOptions(gridOptions, uniqueColumnName) :
      { columnOrder: [] };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public async showExportDialog(type: LSCContentType, gridState: AgGridState): Promise<void> {
    const user = this.authService.getCurrentUser();
    await this.authService.getCurrentUserIdToken();
    const googleCustomerId = user.gSuiteId;
    const primaryEmail = user?.profile?.primaryEmail;
    const googleUserId = user?.profile?.gId;
    const result = await exportGrid(
      this.dialog,
      this.snackBar,
      this.getAccessToken,
      this.exportEndpoint,
      this.projectName,
      type,
      gridState,
      googleCustomerId,
      primaryEmail,
      googleUserId
    );
    if (result.success) {
      await this.auditService.createAuditLog({
        typeId: 13,
        actionId: 180,
        customer_id: this.user.profile.customerId,
        user_id: this.user.profile.id,
        data: result,
      }).toPromise();
    }
  }
}
