import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  BehaviorSubject,
  Observable,
  Subject,
} from 'rxjs';
import {
  shareReplay,
  takeUntil,
} from 'rxjs/operators';

export interface UpdateStatus {
  completed: Date;
  status: string;
  running: boolean;
  started: Date;
}

export interface FeatureLookup {
  [featureName: string]: 'beta' | boolean | FeatureLookup;
}

export interface CustomerFirestore {
  courseworkAnalysisByTeacher: UpdateStatus;
  courseworkAnalysisBySchool: UpdateStatus;
  support: Support;
  settings?: CustomerSettings;
  features?: FeatureLookup;
}

export interface Support {
  url: string;
  description: string;
  defaultMessage: string;
  active: boolean;
}

export interface CustomerSettings {
  forceTeacherNotification: boolean;
}

// @Injectable()
@Injectable({ providedIn: 'root' })
export class FirestoreCustomerService {
  customer: CustomerFirestore;
  private customerRef$: BehaviorSubject<any> = new BehaviorSubject({});
  private userLoggedOut$: Subject<void>;

  constructor(
    private firestore: AngularFirestore) {
    this.userLoggedOut$ = new Subject<void>();
  }

  bindToCustomer(customerId: string) {
    try {
      this.firestore.doc<any>(`customers/${customerId}`)
      .valueChanges()
      .pipe(
        takeUntil(this.userLoggedOut$),
        shareReplay()
      )
      .subscribe((customer) => {
        this.customerRef$.next(customer);
        this.customer = customer;
      });
    } catch (error) {
      console.error('Error getting customer ', error);
    }

    return this.customerRef$;
  }

  getCustomer(): Observable<CustomerFirestore> {
    return this.customerRef$.asObservable();
  }

  patchCustomer(customerId: string, customerPartial): Promise<void> {
    return this.firestore.doc<CustomerFirestore>(`customers/${customerId}`)
    .set(customerPartial, {merge: true});
  }

  userLoggedOut() {
    this.userLoggedOut$.next();
  }

}
